import React, { useState, useEffect } from 'react';

const Bags = ({ product, updateProduct }) => {
  // Initialize state for bagType and selectedMaterial
  const [bagType, setBagType] = useState(product.fields.bagType || '');
  const [selectedMaterial, setSelectedMaterial] = useState(product.fields.material || '');
  const [bagSpecifications, setBagSpecifications] = useState(product.fields.bagSpecifications || []);
  const [width, setWidth] = useState(product.fields.width || '');
  const [height, setHeight] = useState(product.fields.height || '');
  const [gusset, setGusset] = useState(product.fields.gusset || '');
  const [gussetType, setGussetType] = useState(product.fields.gussetType || '');
  const [closureType, setClosureType] = useState(product.fields.closureType || '');
  const [zipperType, setZipperType] = useState(product.fields.zipperType || '');
  const [specialEffects, setSpecialEffects] = useState(product.fields.specialEffects || []);

  useEffect(() => {
    // Update product.fields.bagType whenever bagType state changes
    updateProduct('bagType', bagType);
  }, [bagType, updateProduct]);

  useEffect(() => {
    // Update product.fields.bagSpecifications whenever bagSpecifications state changes
    updateProduct('bagSpecifications', bagSpecifications);
  }, [bagSpecifications, updateProduct]);

  useEffect(() => {
    // Update product.fields.width whenever width state changes
    updateProduct('width', width);
  }, [width, updateProduct]);

  useEffect(() => {
    // Update product.fields.height whenever height state changes
    updateProduct('height', height);
  }, [height, updateProduct]);

  useEffect(() => {
    // Update product.fields.gusset whenever gusset state changes
    updateProduct('gusset', gusset);
  }, [gusset, updateProduct]);

  useEffect(() => {
    // Update product.fields.gussetType whenever gussetType state changes
    updateProduct('gussetType', gussetType);
  }, [gussetType, updateProduct]);

  useEffect(() => {
    // Update product.fields.closureType whenever closureType state changes
    updateProduct('closureType', closureType);
  }, [closureType, updateProduct]);

  useEffect(() => {
    // Update product.fields.zipperType whenever zipperType state changes
    updateProduct('zipperType', zipperType);
  }, [zipperType, updateProduct]);

  useEffect(() => {
    // Update product.fields.specialEffects whenever specialEffects state changes
    updateProduct('specialEffects', specialEffects);
  }, [specialEffects, updateProduct]);

  // Bag types (static, not affecting other options)
  const bagTypes = [
    { value: '1', label: '3 Sided Sealed' },
    { value: '2', label: 'Stand Up with Bottom Gusset' },
    { value: '3', label: '3 Side Sealed with Zipper' },
    { value: '4', label: 'Flat Bottom with Zipper' },
    { value: '5', label: 'Back Side Sealed' },
    { value: '6', label: 'Side Gusset Pouch' },
    { value: '7', label: 'Roll Film' },
    { value: '8', label: 'Spout Bag' }
  ];

  // Material options
  const materialOptions = [
    "PET / VMPET / PE",
    "PET / PE",
    "MATTE OPP / VMPET / PE",
    "PET / AL / PE",
    "PET / LDPE",
    "ALOX",
    "KRAFT PAPER / PE",
    "KRAFT PAPER / VMPET / PE",
    "PE / PE",
    "KRAFT PAPER / PLA",
    "With clear window: Matte opp/PET/PE" // New option added here
  ];

  // Special effects options
  const specialEffectsOptions = [
    "Hologram Stamping",
    "Silver Ink",
    "Variable Data",
    "Hot Stamping 2 Colors",
    "Spot UV",
    "Invisible Black Light Ink",
    "Cold Stamping",
    "Print on Liner",
    "Raised Varnish",
    "Pre Cut (individually cut)",
    "Cold Stamping 2 Colors",
    "Perforation"
  ];

  // Mapping of materials to their specific options
  const materialToOptionsMap = {
    "PET / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "MATTE OPP / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / AL / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / LDPE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "ALOX": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Thin Transparent Aluminum Oxide Coating", "Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: [],
      substrateType: []
    },
    "KRAFT PAPER / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "KRAFT PAPER / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "PE / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "KRAFT PAPER / PLA": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "With clear window: Matte opp/PET/PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Clear", "White"]
    }
  };

  // Handle material change
  const handleMaterialChange = (e) => {
    const material = e.target.value;
    setSelectedMaterial(material);
    updateProduct('material', material);
    // Reset dependent fields
    updateProduct('microns', '');
    updateProduct('layers', '');
    updateProduct('specialFeatures', []);
    updateProduct('printingFinishes', '');
    updateProduct('substrateType', '');
  };

  // Handle bag features change
  const handleBagFeaturesChange = (option) => {
    let updatedFeatures = [...bagSpecifications];
    if (updatedFeatures.includes(option)) {
      updatedFeatures = updatedFeatures.filter(feature => feature !== option);
    } else {
      updatedFeatures.push(option);
    }
    setBagSpecifications(updatedFeatures);
  };

  // Handle special effects change
  const handleSpecialEffectsChange = (effect) => {
    let updatedEffects = [...specialEffects];
    if (updatedEffects.includes(effect)) {
      updatedEffects = updatedEffects.filter(e => e !== effect);
    } else {
      updatedEffects.push(effect);
    }
    setSpecialEffects(updatedEffects);
  };

  // Handle gusset change
  const handleGussetChange = (e) => {
    const value = e.target.value;
    setGusset(value);
    updateProduct('gusset', value === 'yes' ? 'yes' : 'no');
    if (value === 'no') {
      setGussetType('');
      updateProduct('gussetType', '');
    }
  };

  // Handle closure type change
  const handleClosureTypeChange = (e) => {
    const value = e.target.value;
    setClosureType(value);
    updateProduct('closureType', value);
    if (value !== 'Zipper') {
      setZipperType('');
      updateProduct('zipperType', '');
    }
  };

  // Render conditional fields based on selected material
  const renderConditionalFields = () => {
    if (!selectedMaterial) return null;
    const options = materialToOptionsMap[selectedMaterial] || {};

    return (
      <>
        {/* Microns */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Microns:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
            value={product.fields.microns || '120'}
            onChange={(e) => updateProduct('microns', e.target.value)}
          >
            <option value="">Select Microns</option>
            {options.microns.map((micron, index) => (
              <option key={index} value={micron}>{micron}</option>
            ))}
          </select>
        </div>

        {/* Bag Features */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Bag Features:</label>
          <div className="grid gap-2 grid-cols-2">
            {['Rounded Corners', 'Straight Corners', 'Valve', 'Punch Hole', 'Tear Notch', 'Sombrero Hole', 'Tin Tie'].map(option => (
              <label key={option} className="flex items-center">
                <input
                  type="checkbox"
                  checked={bagSpecifications.includes(option)}
                  onChange={() => handleBagFeaturesChange(option)}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Printing Finishes */}
        {options.printingFinishes.length > 0 && (
          <div className="form-group">
            <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Printing Finishes:</label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
              value={product.fields.printingFinishes || ''}
              onChange={(e) => updateProduct('printingFinishes', e.target.value)}
            >
              <option value="">Select Printing Finish</option>
              {options.printingFinishes.map((finish, index) => (
                <option key={index} value={finish}>{finish}</option>
              ))}
            </select>
          </div>
        )}

        {/* Substrate Type */}
        {options.substrateType.length > 0 && (
          <div className="form-group">
            <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Substrate Type:</label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
              value={product.fields.substrateType || ''}
              onChange={(e) => updateProduct('substrateType', e.target.value)}
            >
              <option value="">Select Substrate Type</option>
              {options.substrateType.map((substrate, index) => (
                <option key={index} value={substrate}>{substrate}</option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  };

  // Render Loading Side dropdown
  const renderLoadingSide = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Loading Side:</label>
      <select
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
        value={product.fields.loadingSide || ''}
        onChange={(e) => updateProduct('loadingSide', e.target.value)}
      >
        <option value="">Select Loading Side</option>
        <option value="top">Top</option>
        <option value="bottom">Bottom</option>
        <option value="left">Left Side</option>
        <option value="right">Right Side</option>
      </select>
    </div>
  );

  // Render Gusset input and options
  const renderGussetOptions = () => (
    <div className="form-group">
      {/* Gusset Size Input */}
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Gusset (in mm):</label>
      <input
        type="number"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
        value={gusset}
        onChange={(e) => setGusset(e.target.value)}
      />

      {/* Gusset Radio Options */}
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900 mt-4">Gusset:</label>
      <div className="flex items-center space-x-4">
        <label className="flex items-center">
          <input
            type="radio"
            name="gusset"
            value="yes"
            checked={gusset === 'yes'}
            onChange={handleGussetChange}
            className="mr-2"
          />
          Yes
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name="gusset"
            value="no"
            checked={gusset === 'no'}
            onChange={handleGussetChange}
            className="mr-2"
          />
          No
        </label>
      </div>
      {gusset === 'yes' && (
        <div className="form-group mt-2">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Gusset Type:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
            value={gussetType}
            onChange={(e) => {
              setGussetType(e.target.value);
              updateProduct('gussetType', e.target.value);
            }}
          >
            <option value="">Select Gusset Type</option>
            <option value="Doyan">Doyan</option>
            <option value="K-Seal">K-Seal</option>
          </select>
        </div>
      )}
    </div>
  );

  // Define renderBagSpecifications if needed
  const renderBagSpecifications = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Bag Features:</label>
      <div className="grid gap-2 grid-cols-2">
        {['Rounded Corners', 'Straight Corners', 'Valve', 'Punch Hole', 'Tear Notch', 'Sombrero Hole', 'Tin Tie'].map(option => (
          <label key={option} className="flex items-center">
            <input
              type="checkbox"
              checked={bagSpecifications.includes(option)}
              onChange={() => handleBagFeaturesChange(option)}
              className="mr-2"
            />
            {option}
          </label>
        ))}
      </div>
    </div>
  );

  // Render Closure Type options
  const renderClosureTypeOptions = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Closure Type:</label>
      <div className="flex items-center space-x-4">
        <label className="flex items-center">
          <input
            type="radio"
            name="closureType"
            value="Tin Tie"
            checked={closureType === 'Tin Tie'}
            onChange={handleClosureTypeChange}
            className="mr-2"
          />
          Tin Tie
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name="closureType"
            value="Zipper"
            checked={closureType === 'Zipper'}
            onChange={handleClosureTypeChange}
            className="mr-2"
          />
          Zipper
        </label>
      </div>
      {closureType === 'Zipper' && (
        <div className="form-group mt-2">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Zipper Type:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
            value={zipperType}
            onChange={(e) => {
              setZipperType(e.target.value);
              updateProduct('zipperType', e.target.value);
            }}
          >
            <option value="">Select Zipper Type</option>
            <option value="Regular Zipper">Regular Zipper</option>
            <option value="CR Zipper">CR Zipper</option>
            <option value="Slider Zipper">Slider Zipper</option>
            <option value="Double Zipper">Double Zipper</option>
            <option value="Pull Tab Zipper">Pull Tab Zipper</option>
            <option value="Velcro Zipper">Velcro Zipper</option>
          </select>
        </div>
      )}
    </div>
  );

  // Render Special Effects options
  const renderSpecialEffectsOptions = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Special Effects:</label>
      <div className="grid gap-2 grid-cols-2">
        {specialEffectsOptions.map(effect => (
          <label key={effect} className="flex items-center">
            <input
              type="checkbox"
              checked={specialEffects.includes(effect)}
              onChange={() => handleSpecialEffectsChange(effect)}
              className="mr-2"
            />
            {effect}
          </label>
        ))}
      </div>
    </div>
  );

  return (
    <div className="product-form">
      {/* Bag Type */}
      <div className="form-group">
        <img src="https://shipping-quote.labelslab.com/bags.png" alt="BagsImage" />
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Select Bag Type:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={bagType}
          onChange={(e) => setBagType(e.target.value)}
        >
          <option value="">Select Bag Type</option>
          {bagTypes.map((type) => (
            <option key={type.value} value={type.value}>{`${type.value} - ${type.label}`}</option>
          ))}
        </select>
      </div>

      {/* Width */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Width (in mm):</label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      </div>

      {/* Height */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Height (in mm):</label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>

      {/* Gusset Options */}
      {renderGussetOptions()}

      {/* Material */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Material:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={selectedMaterial}
          onChange={handleMaterialChange}
        >
          <option value="">Select Material</option>
          {materialOptions.map((material, index) => (
            <option key={index} value={material}>{material}</option>
          ))}
        </select>
      </div>

      {/* Closure Type Options */}
      {renderClosureTypeOptions()}

      {/* Conditional Fields Based on Material */}
      {renderConditionalFields()}

      {/* Additional Fields */}
      {renderBagSpecifications()}

      {/* Loading Side */}    
      {renderLoadingSide()}

      {/* Special Effects */}
      {renderSpecialEffectsOptions()}

    </div>
  );
};

export default Bags;
